/* Media queries for height */

@media (max-height: 750px)   {
    .onboarding-success-ht{
        height: calc(100vh - 200px) !important;
    }
    /* .fullscreen-box-wrapper-onboarding {
        min-height: calc(100vh - 450px) !important;
        height: calc(100vh - 450px) !important;
    }
    .fullscreen-box-wrapper-home{
        min-height: calc(100vh - 445px) !important;
        height: calc(100vh - 445px) !important;
    }
    .fullscreen-box-wrapper-notif{
        min-height: calc(100vh - 350px) !important;
        height: calc(100vh - 350px) !important;
    } */
}